
<template>
    <el-container>
        <el-main>
            <el-form ref="addForm"  :model="formData" :rules="rules" label-width="120px" label-position="left">
                <el-form-item label="公告标题" prop="annoTitle">
                    <el-input v-model="formData.annoTitle" class="inner-input" placeholder="请输入公告标题"></el-input>
                </el-form-item>

                <el-form-item prop="annoIcon" label="公告图标">
                    <el-upload
                            class="avatar-uploader"
                            accept="image/gif,image/jpeg,image/png"
                            :action="''"
                            :show-file-list="false"
                            :http-request="uploadIconImage"
                    >
                        <img v-if="formData.annoIcon" :src="formData.annoIcon" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item prop="annoContent" label="公告内容">
                    <el-upload
                            class="avatar-uploader"
                            accept="image/gif,image/jpeg,image/png"
                            :action="''"
                            :show-file-list="false"
                            :http-request="uploadImage"
                    >
                        <img v-if="formData.annoContent" :src="formData.annoContent" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" :loading="loading" @click="handleSubmit" style="margin-left: 10px">确 定</el-button>
                    <el-button @click="handleClose">取 消</el-button>
                </el-form-item>

            </el-form>
        </el-main>
    </el-container>
</template>

<script>
import {ploadFile} from "@/api";
import request from '@/utils/request'

export default {
    name: "editGameNotice",
    props: {
        row: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            loading: false,
            formData: {
                annoTitle: '',//公告标题
                annoIcon: '',//公告图标
                annoContent: '',//公告内容
            },
            rules: {
                annoTitle: [
                    {required: true, message: '请输入公告标题！', trigger: 'change'},
                ],
                annoContent: [
                    {required: true, message: '请输入公告内容！', trigger: 'change'}
                ]
            },
        }
    },
    methods: {
        async getDetail() {
            try {
                let {data} = await request({
                    url: 'platform/activity/announcement/detail',
                    method: 'post',
                    data: {
                        id: this.row.id
                    }
                })
                this.formData = data
            } catch (e) {

            }
        },
        async uploadIconImage({file}) {
            try {
                // 根据后台需求数据格式
                const formData = new FormData()
                // 文件对象
                formData.append('filedata', file)
                let {data} = await ploadFile(formData)
                this.formData.annoIcon = data.url
                console.log(data)

            } catch (e) {

            }
        },
        async uploadImage({file}) {
            try {
                // 根据后台需求数据格式
                const formData = new FormData()
                // 文件对象
                formData.append('filedata', file)
                let {data} = await ploadFile(formData)
                this.formData.annoContent = data.url
                console.log(data)

            } catch (e) {

            }
        },
        handleSubmit() {
            this.$refs["addForm"].validate(async valid => {
                if (valid) {
                    try {
                        this.loading = true
                        let formData = this.formData;
                        await request({
                            url: 'platform/activity/announcement/editDo',
                            method: 'post',
                            data: formData
                        })
                        this.loading = false;
                        this.$emit('success')
                        this.$message({
                            type: "success",
                            message: "更新成功!"
                        });
                    } catch (e) {
                        this.loading = false;
                    }
                }
            })
        },
        handleClose() {
            this.$emit('cancel')
        }
    },
    mounted() {
        this.getDetail()
    }
}
</script>

<style scoped lang="less">
.avatar-uploader {
    display: inline-block;
    width: auto;

    /deep/ .el-upload {
        width: 146px;
        height: 146px;
        line-height: 146px;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .el-upload:hover {
        border-color: #409EFF;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 146px;
        height: 146px;
        line-height: 146px;
        text-align: center;
        border: 1px dashed #dedede;
        border-radius: 10px;
    }

    .avatar {
        width: 146px;
        height: 146px;
        display: block;
    }
}
</style>
