<template>
    <el-container class="page-container">
        <!-- 搜索栏 -->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-width="120px" label-position='left' :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label="公告标题" prop="annoTitle">
                            <el-input v-model="query.annoTitle" size="medium" class="inner-input"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="12">
                        <el-form-item label="创建时间" prop="dateArr">
                            <el-date-picker
                                    v-model="query.dateArr"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"
                                    size="medium"
                                    :default-time="['00:00:00', '23:59:59']"
                                    value-format="timestamp"
                            >
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="getData(1)">搜索
                        </el-button>
                        <el-button
                                style="vertical-align: top"
                                type="primary"
                                size="medium"
                                @click="clearSearch">清除
                        </el-button>
                    </el-col>
                </el-row>
            </el-form>
            <div>
                <el-button type="primary" size="small" @click="handleOpenDialog">新增</el-button>
            </div>
            <br>
            <el-table
                    :data="result.list"
                    tooltip-effect="light"
                    border
                    stripe
                    style="width: 100%"
            >
                <el-table-column
                        prop="annoTitle"
                        label="公告标题">
                </el-table-column>
                <el-table-column
                        label="内容">
                    <template slot-scope="scope">
                        <el-image
                                style="width: 48px; height: 48px"
                                :src="scope.row.annoContent"
                                :preview-src-list="[scope.row.annoContent]">
                        </el-image>
                    </template>
                </el-table-column>

                <el-table-column
                        label="状态">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.enabled?'primary':'danger'">{{scope.row.enabled?'发布':'未发布'}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        :formatter="fomartDate"
                        label="创建时间">
                </el-table-column>
                <el-table-column
                        width="120px"
                        fixed="right"
                        label="操作">
                    <template slot-scope="scope">
                        <el-dropdown @command="handleCommand">
                            <el-button type="primary" size="small">
                                更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:1,row:scope.row}">
                                    编辑
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:2,row:scope.row}">
                                    切换状态
                                </el-dropdown-item>
                                <el-dropdown-item :command="{type:3,row:scope.row}">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <!-- 分页 -->
            <el-pagination

                    @current-change="getData"
                    @size-change="handleSizeChange"
                    :current-page="query.pageNumber"
                    :page-sizes="pageSizes"
                    :page-size="query.pageSize"
                    layout="total,sizes,prev, pager, next, jumper"
                    :total="result.totalCount">
            </el-pagination>
        </el-main>
        <el-dialog
                title="新增赛事公告"
                width="800px"
                :close-on-click-modal="false"
                :visible.sync="addGameNoticeDialog"
                :close-on-press-escape="false"
                :before-close="handleClose">
            <add-game-notice v-if="addGameNoticeDialog" @success="handleClose(1)" @cancel="handleClose"/>
        </el-dialog>
        <el-dialog
                title="编辑赛事公告"
                width="800px"
                :close-on-click-modal="false"
                :visible.sync="editGameNoticeDialog"
                :close-on-press-escape="false"
                :before-close="handleClose">
            <edit-game-notice :row="row" v-if="editGameNoticeDialog" @success="handleClose(1)" @cancel="handleClose"/>
        </el-dialog>
    </el-container>

</template>

<script>
import request from '@/utils/request'

import addGameNotice from "@/views/activeManage/components/addGameNotice";
import editGameNotice from "@/views/activeManage/components/editGameNotice";
import _ from 'lodash'
import moment from 'moment'

export default {
    name: 'gameNotice',
    components: {addGameNotice, editGameNotice},
    data() {
        return {
            result: {
                list: [],
                totalCount: 0
            },
            row: {},
            loading: true,
            // 分页
            pageSizes: [10, 20, 30, 40],
            query: {
                pageSizes: 10, // 分码
                pageNumber: 1, // 每页条数
                annoTitle: '', // 公告标题
                dateArr: [],
            },
            addGameNoticeDialog: false,
            editGameNoticeDialog: false
        };
    },
    methods: {
        fomartDate(row) {
            console.log(row)
            return moment(row.opAt * 1000).format('YYYY-MM-DD')
        },
        async handleCommand(command) {
            let type = command.type
            this.row = _.cloneDeep(command.row)
            switch (type) {
                case 1:
                    this.editGameNoticeDialog = true
                    break;
                case 2:
                    try {
                        await this.$confirm('确定修改公告状态？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        let data = await request({
                            url: 'platform/activity/announcement/changeStatus',
                            methods: 'post',
                            data: {
                                id: command.row.id,
                                enabled:!command.row.enabled
                            }
                        })
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.getData();
                    }catch (e) {

                    }
                    break;
                case 3:
                    try {
                        await this.$confirm('删除后无法恢复，确定删除吗？', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        })
                        let data = await request({
                            url: 'platform/activity/announcement/delete',
                            methods: 'post',
                            data: {
                                id: command.row.id
                            }
                        })
                        this.$message({
                            message: data.msg,
                            type: 'success'
                        });
                        this.getData();
                    }catch (e) {

                    }
            }
        },
        handleClose(type) {
            if (type === 1) {
                this.getData()
            }
            this.addGameNoticeDialog = false
            this.editGameNoticeDialog = false
        },
        handleOpenDialog() {
            this.addGameNoticeDialog = true

        },
        handdleGoPage() {
            this.$router.push({name: 'index'})
        },
        clearSearch() {
            this.$refs.searchForm.resetFields();
            this.getData()
        },
        // 分页
        handleSizeChange(val) {
            this.query.pageSizes = val;
            this.getData();
        },
        async getData(page) {
            try {
                if (this.query.dateArr && this.query.dateArr.length) {
                    this.query.start = this.query.dateArr[0]/1000;
                    this.query.end = this.query.dateArr[1]/1000;
                } else {
                    this.query.start = '';
                    this.query.end = '';
                }
                let formData = {}
                formData.pageNumber = page || 1;
                this.query.pageNumber = page || 1;
                for (let i in this.query) {
                    if (this.query[i] && i !== 'dateArr') {
                        formData[i] = this.query[i]
                    }
                }
                let {data} = await request({
                    url: 'platform/activity/announcement/data',
                    method: 'get',
                    data: formData
                })
                this.result = data;
            }catch (e) {

            }
        },

    },

    async mounted() {
        this.loading = false;
        this.getData();
    }

};
</script>

<style scoped lang="less">
.avatar-uploader {
    height: 70px;
    width: 70px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #dcdcdc;
    border-radius: 4px;

    &.el-upload:hover {
        border-color: #409EFF;
    }

    .cw-icon-tupian {
        font-size: 28px;
        color: #8c939d;
    }

    .el-upload {
        height: 100%;
        display: flex !important;
        justify-content: center;
        align-items: center;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .avatar {
        height: 100%;
        width: 100%;
        display: block;
    }
}
</style>

